import React, { CSSProperties, ReactElement } from 'react';

const EmailIcon = ({ className, style }: { className?: string; style?: CSSProperties }): ReactElement => {
    return (
        <div className={`drop-files-icon ${className ?? ''}`} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Mail Send Envelope">
                    <path
                        id="Vector"
                        d="M1 6.02381L10.9169 11.7297C11.221 11.9005 11.6043 11.994 12 11.994C12.3957 11.994 12.779 11.9005 13.0831 11.7297L23 6.02381M21.3077 4H2.69231C1.75767 4 1 4.72488 1 5.61905V19.381C1 20.2752 1.75767 21 2.69231 21H21.3077C22.2424 21 23 20.2752 23 19.381V5.61905C23 4.72488 22.2424 4 21.3077 4Z"
                        stroke="#1A497F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </svg>
        </div>
    );
};

export default EmailIcon;
