import { graphql, navigate } from 'gatsby';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';
import AddAdultSendInvitationFlow from 'components/add-adult-send-invitation-flow';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';

import './index.scss';

type AddAdultSendInvitationPageProps = {
    location: any;
    data: GatsbyTypes.AddAnAdultDataQuery;
};

type ButtonBackProps = {
    onClick: () => void;
    label: string;
    className?: string;
};

const ButtonBack: React.FC<ButtonBackProps> = (props) => {
    const { onClick: foreignOnClick, label, className } = props;

    const onClick = useCallback(() => {
        if (foreignOnClick) return foreignOnClick();

        window.history.back();
    }, [foreignOnClick]);

    return (
        <button className={`btn btn-link no-min-width ${className}`} onClick={onClick}>
            <ChevronIcon direction={'left'} /> {label}
        </button>
    );
};

const AddAdultSendInvitationPage: React.FC<AddAdultSendInvitationPageProps> = (props) => {
    const { data } = props;

    const { t } = useTranslation();

    return (
        <div className="add-an-adult__send-invitation__wrapper">
            <WorkflowLayout
                backgroundImage={data.feathersBackground}
                useRoundedCorners={false}
                metaData={{ nodeTitle: t(`pages.profile.addFamilyMember.eyebrowText.adult`) }}
                anonymousOnly={false}
            >
                <WorkflowLayoutFormWrapper
                    eyebrowText={t(`pages.profile.addFamilyMember.eyebrowText.adult`)}
                    title={t(`pages.profile.addFamilyMember.sendInvitation`)}
                    nav={
                        <ButtonBack
                            onClick={() => {
                                navigate('/secure/profile/family-account/add-an-adult');
                            }}
                            label={t(`pages.profile.addFamilyMember.back`)}
                        />
                    }
                >
                    <AddAdultSendInvitationFlow />
                </WorkflowLayoutFormWrapper>
            </WorkflowLayout>
        </div>
    );
};

export default AddAdultSendInvitationPage;

export const query = graphql`
    query AddAdultSendInvitationData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        feathersBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
