import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { familyProfileDependentToAddSelector } from '../../state/family-profile/family-profile.selectors';
import AddFamilyMemberSendInvitation from '../../display-components/add-family-member/send-invitation';
import { useTranslation } from 'react-i18next';
import { accountProfileSelector } from 'state/account/account.selectors';
import { getAge } from 'const/options';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { navigate } from 'gatsby';
import storageHelper from 'util/storageHelper';
import { BirdiModalHeaderDanger, BirdiModalHeaderWithBackgroundColor } from 'components/birdi-modal/birdi-modal-header';
import {
    FamilyAccountAddMemberAlreadyMemberErrorModalContent,
    FamilyAccountAddMemberGenericErrorModalContent,
    FamilyAccountAddMemberInvitationErrorModalContent,
    FamilyAccountAddMemberInvitationSentModalContent
} from 'display-components/add-family-member/modals';
import {
    familyProfileAddDependentRoutine,
    familyProfileSendDependentInvitationRoutine
} from 'state/family-profile/family-profile.routines';
import { FamilyAccountAddDependentResponse } from 'types/family-management';

type DependentTreatedData = {
    firstName: string;
    lastName: string;
    age: string;
    dateOfBirth: string;
    prescriptionNumber: string;
    insuranceID?: string;
    accountType: number;
    email: string | null;
    phone: string | null;
};

type AddAdultSendInvitationFlowProps = {};

export const AddAdultSendInvitationFlow: React.FC<AddAdultSendInvitationFlowProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultBackUrl = '/secure/profile/family-account/add-an-adult/';
    const successRedirectUrl = '/secure/profile/family-account/';
    const cookie = storageHelper.cookies;

    const dependentToAddSelector = useSelector(familyProfileDependentToAddSelector);
    const profileObject = useSelector(accountProfileSelector);

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dependentData, setDependentData] = useState<Partial<DependentTreatedData>>({});

    const [epostPatientNum, setEpostPatientNum] = useState<string>('');

    useEffect(() => {
        if (!dependentToAddSelector || !dependentToAddSelector.FirstName) {
            navigate(defaultBackUrl);
        }

        if (dependentToAddSelector) {
            const dob = new Date(dependentToAddSelector.DateOfBirth);
            const dobMonthFormatted = `0${dob.getMonth() + 1}`.slice(-2);

            setIsLoading(false);
            setDependentData({
                firstName: dependentToAddSelector?.FirstName,
                lastName: dependentToAddSelector?.LastName,
                age: getAge(dob).toString(),
                dateOfBirth: `${dobMonthFormatted}/${dob.getDate()}/${dob.getFullYear()}`,
                prescriptionNumber: dependentToAddSelector?.PrescriptionNumber,
                accountType: dependentToAddSelector?.AccountType,
                email: dependentToAddSelector?.Email,
                phone: dependentToAddSelector?.PhoneNumber
            });
        }
    }, [dependentToAddSelector]);

    useEffect(() => {
        if (profileObject && profileObject.epostPatientNum) {
            setEpostPatientNum(profileObject.epostPatientNum);
        }
    }, [profileObject]);

    const showInvitationSentSuccessModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'primary',
                    size: 'lg',
                    onClose: () => {
                        setIsBusy(false);
                        navigate(successRedirectUrl);
                        dispatch(closeModal({}));
                    },
                    headerContent: (
                        <BirdiModalHeaderWithBackgroundColor
                            headerText={t('pages.profile.addFamilyMember.invitationSentModal')}
                            type="success"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberInvitationSentModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                navigate(successRedirectUrl);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const showInvitationSentFailureModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => {
                        setIsBusy(false);
                        navigate(defaultBackUrl);
                        dispatch(closeModal({}));
                    },
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberInvitationErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                navigate(defaultBackUrl);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const showGenericErrorModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberGenericErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    // Show error modal when use is already add to another account.
    const showDependentAlreadyAddedErrorModal = useCallback(
        (dependentName: string) => {
            dispatch(
                openModal({
                    showClose: false,
                    type: 'danger',
                    size: 'lg',
                    onClose: () => setIsBusy(false),
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('pages.profile.addFamilyMember.genericErrorModalTitle')}
                            icon="alert"
                        />
                    ),
                    bodyContent: (
                        <FamilyAccountAddMemberAlreadyMemberErrorModalContent
                            name={dependentName}
                            translation={t}
                            onContinueClick={() => {
                                setIsBusy(false);
                                dispatch(closeModal({}));
                            }}
                        />
                    ),
                    ctas: []
                })
            );
        },
        [dispatch, t]
    );

    const handleOnSubmit = (contactMethod?: string) => {
        if (dependentData) {
            setIsBusy(true);
            dispatch(
                familyProfileAddDependentRoutine.trigger({
                    dependentData: {
                        FirstName: dependentData.firstName,
                        LastName: dependentData.lastName,
                        DateOfBirth: dependentData.dateOfBirth,
                        PrescriptionNumber: dependentData.prescriptionNumber,
                        EPostPatientNumber: epostPatientNum,
                        AccountType: 'Adult'
                    },
                    onSuccess: (response: FamilyAccountAddDependentResponse) => {
                        const addedDependentData = response.Data;

                        dispatch(
                            familyProfileSendDependentInvitationRoutine.trigger({
                                data: {
                                    CommunicationType: contactMethod,
                                    EpostPatientNumber: addedDependentData.EPostPatientNum,
                                    CareGiverDependentId: addedDependentData.ID
                                },
                                onSuccess: (response: FamilyAccountAddDependentResponse) => {
                                    showInvitationSentSuccessModal(addedDependentData.FirstName);
                                    /**
                                     * Ticket: DRX-2668
                                     * This cookie is needed to remember the communication method used
                                     * during the send invitation on add dependent flow.
                                     */
                                    cookie.setDependentSendInvitationMethodCookie(
                                        epostPatientNum,
                                        addedDependentData.ID,
                                        addedDependentData.EPostPatientNum,
                                        contactMethod as string
                                    );
                                },
                                onFailure: (errors: FamilyAccountAddDependentResponse['Errors']) => {
                                    showInvitationSentFailureModal(addedDependentData.FirstName);
                                }
                            })
                        );
                    },
                    onFailure: (errors: FamilyAccountAddDependentResponse['Errors']) => {
                        if (errors[0].search('has already been added') >= 0) {
                            showDependentAlreadyAddedErrorModal(dependentData.firstName || '');
                        } else {
                            showGenericErrorModal(dependentData.firstName || '');
                        }
                    }
                })
            );
        }
    };

    const handleOnCancel = () => {
        navigate(defaultBackUrl, { state: dependentToAddSelector ?? undefined });
    };

    return (
        <div>
            {isLoading ? (
                <div className="loading">{t('pages.profile.addFamilyMember.loadingText')}</div>
            ) : (
                <AddFamilyMemberSendInvitation
                    dependentData={dependentData as Required<DependentTreatedData>}
                    isBusy={isBusy}
                    onSubmit={(contactMethod) => handleOnSubmit(contactMethod)}
                    onCancel={handleOnCancel}
                />
            )}
        </div>
    );
};

export default AddAdultSendInvitationFlow;
