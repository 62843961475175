// General
import { ReactElement } from 'react';
import classNames from 'classnames';

// Hooks
import { useTranslation } from 'react-i18next';

// Ui-kit
import BorderedContainer from 'ui-kit/bordered-container/bordered-container';
import IconBackgroundColor from 'ui-kit/icon-background-color';

// Ui-kit: icons
import EmailIcon from 'ui-kit/icons/email/email';
import PhoneIcon from 'ui-kit/icons/phone/phone';

// Utils
import { maskEmail, maskPhoneNumber } from 'util/string';

// Styles
import './contact-info-box.style.scss';

// Contact Type
export type ContactType = 'email' | 'phone';

// Interface for the main component
interface ContactInfoBoxProps {
    id: string;
    contactType: ContactType;
    contactData: string;
    isChecked?: boolean;
    hasError?: boolean;
    className?: string;
    onChangeHandler: () => void;
}

// Helper function to show the correct card icon depending on the contact type information.
const ContactInfoBoxIcon = ({ contactType }: { contactType: ContactType }): ReactElement => {
    switch (contactType) {
        case 'email':
            return (
                <IconBackgroundColor>
                    <EmailIcon />
                </IconBackgroundColor>
            );

        case 'phone':
            return (
                <IconBackgroundColor>
                    <PhoneIcon />
                </IconBackgroundColor>
            );

        default:
            return <></>;
    }
};

// Main component
const ContactInfoBox = ({
    id,
    contactType,
    contactData,
    isChecked,
    hasError,
    className,
    onChangeHandler
}: ContactInfoBoxProps) => {
    const { t } = useTranslation();
    const containerClasses = classNames('contact-info-box', className, { error: hasError }, { selected: isChecked });

    return (
        <BorderedContainer className={containerClasses}>
            <div className="contact-info-box__icon-container">
                <ContactInfoBoxIcon contactType={contactType} />
            </div>
            <div className="contact-info-box__content-container">
                <div className="label">{t(`components.contactInfoBox.${contactType}`)}</div>
                <div className="value">
                    {contactType === 'email' ? maskEmail(contactData) : maskPhoneNumber(contactData)}
                </div>
            </div>
            <div className="contact-info-box__input-container">
                <input
                    type="radio"
                    name="contact-info-box-option"
                    id={id}
                    checked={isChecked}
                    onChange={onChangeHandler}
                />
            </div>
        </BorderedContainer>
    );
};

export default ContactInfoBox;
